.react-cookie-law-dialog {
  background-color: #ffffff;
  position: fixed;
  top: 50px;
  left: 50%;
  z-index: 99999999;
  transform: translate(-50%);
  width: 100%;
  max-width: 900px;
  margin-bottom: 20px;
  padding:30px;

  .react-cookie-law-container {
    .react-cookie-law-msg {
      margin-bottom: 20px;
      margin-top: 20px;
    }
    @include media-query($small-down) {
      width: 100%;
      height: calc(100% - 80px);
      overflow-y: scroll;
      
    }
  }

  .react-cookie-law-container::before {
    content:"Cookie Settings";
    font-family: "LufthansaHead", sans-serif;
    font-weight: bold;
    white-space: pre-wrap;
    color: #333;
    font-size: 52px;
    line-height: 54px;
  }

  .react-cookie-law-select-pane{
    margin-bottom: 30px;

    @include media-query($medium-down) {
      margin-bottom: 0px;
    }

    .react-cookie-law-option-wrapper {
      display: inline-block;
      width: 25%;
      position: relative;
      margin-right: 0px;
      margin-left: 0px;
      
      @include media-query($medium-down) {
        width: 50%;
        margin-bottom: 20px;
      }

      @include media-query($small-down) {
        width: 100%;
        margin-bottom: 20px;
      }

      label {
        background-color: green;
        height: auto;
        width: auto;
        min-height: 14px;
        color: rgb(198, 198, 198);
        display: inline-block;
        padding: 1px 0px 0px 10px;
        position: relative;
        top: 0px;
        left: 0px;
        z-index: 1;
        cursor: default;
        vertical-align: top;
      }

      input[type="checkbox"] {
        display:none;
      }

      input[type="checkbox"] + label {
        width: 30px;
        height: 15px;
        border-radius: 15px;
        border: 2px solid #ddd;
        background-color: #EEE;
        display: inline-block;
        content: "";
        margin-right: 5px;
        transition: background-color 0.5s linear;
      }

      input[type="checkbox"] + label:hover {
        cursor: pointer;
      }

      input[type="checkbox"] + label::before {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        background-color: #fff;
        display: block;
        content: "";
        float: left;
        margin-right: 5px;
        transition: margin 0.1s linear;
        box-shadow: 0px 0px 5px #aaa;
      }

      input[type="checkbox"] + label::after {
        position: absolute;
        left: 60px;
        top: - 5px;
      }

      #check-required-cookies + label::after {
        content: "Necessary";
      }

      #check-preferences-cookies + label::after {
        content: "Comfort";
      }

      #check-statistics-cookies + label::after {
        content: "Statistics";
      }

      #check-marketing-cookies + label::after {
        content: "Personalization";
      }

      input[type="checkbox"]:checked+label {
        background-color: #2b8718;
      }

      input[type="checkbox"]:checked+label::before {
        margin: 0 0 0 15px;
      }
    }
  }
  
  .react-cookie-law-option-checkbox {
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 4px;
    border: 2px solid $color-grey-dark;
  }

  .react-cookie-law-policy {
    margin-bottom: 30px;
  }

  .react-cookie-law-button-wrapper {
    float: right;

    button {
      margin-bottom: 20px;
    }

    @include media-query($small-down) {
      width: 100%;
      position: fixed;
      left: 5%;
      width: 90%;
      bottom: 10px;

      button {
        margin-bottom: 10px;
      }
    }
  }

  .react-cookie-law-accept-btn {
    cursor: pointer;
    text-align: center;
    border-radius: 4px;
    background-color: #ffad00;
    border: 1px solid #ffad00;
    color: #05164D;
    height: 50px;
    min-height: 50px;
    line-height: 24px;
    padding: 11px 30px;
    font-size: 18px;
    font-weight: 700;
    transition: 200ms ease all;

    @include media-query($small-down) {
      width: 100%;
      font-size: 16px;
      line-height: 4px;
      min-height: 30px;
      height: 30px;
    }
  }

  .react-cookie-law-accept-btn:hover {
    background-color: #ffbd32;
  }

  .react-cookie-law-manage-btn,
  .react-cookie-law-save-btn {
    cursor: pointer;
    text-align: center;
    border-radius: 4px;
    background-color: #fff;
    border: 2px solid #05164D;
    color: #05164D;
    height: 50px;
    min-height: 50px;
    line-height: 24px;
    padding: 11px 30px;
    font-size: 18px;
    font-weight: 700;
    margin-right: 30px;
    transition: 200ms ease all;

    &:hover {
      background-color: #05164D;
      color: #fff;
    }

    @include media-query($medium-down) {
     float: left;
    }

    @include media-query($small-down) {
      width: 100%;
      font-size: 16px;
      line-height: 4px;
      min-height: 30px;
      height: 30px;
    }
  }
  
  @include media-query($medium-down) {
    width: 90%;
    
  }
  @include media-query($small-down) {
    width: 80%;
    height: calc(100% - 130px);
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

.inline {
  display: inline;
}

button.inline {
  padding:0px;
  background: transparent;
  border: none;
  text-decoration: none;

}

button.inline:hover {
  text-decoration: underline;
  cursor: pointer;
}

button.inline:active, button.inline:visited {
  color: red;
}

.copy a {
    display: inline;
}
