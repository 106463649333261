nav.Navigation {
  position: absolute;
  top: 0;
  width: 100%;
  margin-top: 48px;
  font-Size: 17px;
  line-height: 27px;
  z-index: 9999;

  @include media-query($medium-down) { margin-top: 0; }

  a, a:visited {
    display: block;
    color: $color-grey-medium;
    text-decoration: none;
  }

  &.theme {
    &-bright,
    &-dark {
      .NavigationMain,
      .NavigationSub {
        &.open {
          .NavigationSub {
            &-indicator {
              a:hover, a.active { color: $color-black;}
            }
          }
        }

        &.sticky {
          a.NavigationMain-item,
          a.NavigationSub-item {
            &:hover, &.active { color: $color-black; }
          }

          .NavigationMain-logo { fill: $color-black; }

          .NavigationSub {
            &-indicator {
              a:hover, a.active { color: $color-black;}
            }

            &-toggle svg.icon-open { fill: $color-black; }
          }
        }
      }

      .NavigationSub {
        &-indicator {
          a:hover, a.active { color: $color-black;}
        }

        &-toggle svg.icon-open { fill: $color-black; }
      }
    }

    &-bright {
      a.NavigationMain-item,
      a.NavigationSub-item {
        &:hover, &.active { color: $color-black; }
      }

      .NavigationMain-logo {fill: $color-black; }

      .NavigationDivider { opacity: .6; }

      .NavigationSub {
        &-indicator {
          a:hover, a.active { color: $color-black;}
        }

        &-toggle svg.icon-open { fill: $color-black; }
      }
    }

    &-dark {
      a.NavigationMain-item,
      a.NavigationSub-item {
        &:hover, &.active { color: $color-white; }

        @include media-query($medium-down) {
          &:hover, &.active { color: $color-black; }
        }
      }

      .NavigationMain-logo {
        fill: $color-white;

        @include media-query($medium-down) {
          fill: $color-black;
        }
      }

      .NavigationDivider { opacity: .4; }

      .NavigationSub {
        &-indicator {
          a:hover, a.active { color: $color-white; }
        }

        &-toggle svg.icon-open { fill: $color-white; }
      }
    }
  }

  .NavigationMain,
  .NavigationDivider,
  .NavigationSub {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    @include transition(background-color .25s ease-in-out);

    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 1200px;
      padding: 0 16px;
      
      @include media-query($large-down) {
        padding: 0 $gutter-space;
        box-sizing: border-box;
      }
    }

    &-item {
      display: inline-block;
      margin: 18px 24px;
      @include transition(color .25s ease-in-out);

      &.active { font-weight: bold; }
      &:hover { cursor: pointer; }
      &:last-child { margin-right: 0; }
    }

    @include media-query($medium-down) {
      font-size: 20px;
      line-height: 27px;
    }

    @include media-query($medium-up) {
      &.sticky {
        position: fixed;
        top: 0;
        margin-top: 0;
        background-color: $color-white;
        @include transition(background-color .25s ease-in-out);

        .NavigationMain,
        .NavigationSub {
          &-indicator { visibility: visible; }
        }
      }
    }
  }

  .NavigationMain {
    height: 60px;
    z-index: 2000;
    
    &-legal {
      display: block;
      margin-top: $gutter-space;
      text-align: center;
      opacity: 0;
      @include transform(translateY(-20px));

      &.active { font-weight: bold; }
    }

    &-logo {
      display: inline-block;
      width: 109px;
      margin-top: 6px;
    }

    &-toggle {
      position: relative;
      display: none;
      height: 12px;
      width: 24px;
      cursor: pointer;

      .bar {
        position: absolute;
        height: 3px;
        width: 24px;
        @include border-radius(3px);
        background-color: $color-black;
        @include transition(
          top .2s ease-out .1s,
          transform .2s ease-out
        );

        &:first-child { top: 1px; }
        &:last-child { top: 11px; }
      }
    }

    @include media-query($medium-down) {
      position: absolute;
      height: 48px;
      background-color: $color-white;
      @include transition(height .3s cubic-bezier(.645, .045, .355, 1));

      a, a:visited { color: $color-black; }

      &-icons {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: $gutter-space / 4 $gutter-space;
        background-color: $color-white;
        box-sizing: border-box;
      }

      &-items {
        position: absolute;
        top: -100vh;
        left: 0;
        right: 0;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        padding: 0 $gutter-space;
        box-sizing: border-box;
      }

      &-item {
        display: block;
        padding: $gutter-space 0;
        margin: 0;
        border-bottom: 1px solid rgba(197, 197, 197, .4);
        color: $color-black;
        text-align: center;
        opacity: 0;
        @include transform(translateY(-20px));

        &:hover, &.active { color: $color-black; }
        &:first-child { border-top: 1px solid rgba(197, 197, 197, .4); }
        &:last-child {
          padding: 0;
          border: none;
        }
      }

      @for $i from 0 to 6 {
        &-item:nth-child(#{$i}n) {
          @include transition(
            opacity .1s ease #{(5 - $i) * .03}s,
            transform .1s ease #{(5 - $i) * .03}s
          );
        }
      }

      &-logo {
        margin-top: 8px;

        svg { width: 80px; }
      }

      &-toggle { display: block; }

      &.open {
        height: 100vh;
        @include transition(height .3s cubic-bezier(.645, .045, .355, 1));

        .NavigationMain {
          &-items {
            top: 50%;
            @include transform(translateY(-50%));
            @include transition(top 0s cubic-bezier(.645, .045, .355, 1) 0s);
          }

          &-item {
            opacity: 1;
            @include transform(translateY(0));
          }

          @for $i from 0 to 6 {
            &-item:nth-child(#{$i}n) {
              @include transition(
                opacity .1s ease #{.1 + ($i) * .03}s,
                transform .1s ease #{.1 + ($i) * .03}s
              );
            }
          }
          
          &-legal {
            opacity: 1;
            @include transform(translateY(0));
            @include transition(
              opacity .1s ease .28s,
              transform .1s ease .28s,
            );
          }

          &-toggle {
            .bar {
              top: 6px;
              @include transition(top .2s ease-out, transform .2s ease-out .1s);
            }

            .bar:first-child { @include transform(rotate(45deg)); }
            .bar:last-child { @include transform(rotate(-45deg)); }
          }
        }
      }

      &.sticky { position: fixed; }
    }
  }

  .NavigationDivider {
    @include media-query($medium-down) {
      display: none;
    }
  }

  .NavigationSub {
    height: 60px;
    z-index: 1000;

    &-wrapper-outer {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      width: 100%;
    }

    &-indicator {
      display: flex;
      align-items: center;
      font-weight: bold;
      visibility: hidden;
    }

    &-toggle {
      display: none;
      flex-grow: 1;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-left: $gutter-space / 2;
      z-index: 1000;

      .icon {
        &-close, &-open {
          height: 24px;
          width: 24px;
        }

        &-close {
          opacity: 0;
          visibility: hidden;
          @include transition(
            opacity .1s ease-out .1s,
            visibility .1s ease-out
          );
        }

        &-open {
          margin-top: 3px;
          opacity: 1;
          visibility: visible;
          @include transform(rotate(90deg));
          @include transition(
            opacity .1s ease-out .1s,
            visibility .1s ease-out
          );
        }
      }
    }

    @include media-query($medium-down) {
      position: absolute;
      top: 48px;
      height: 54px;
      @include transition(
        height .3s cubic-bezier(.645, .045, .355, 1),
        background-color .2s ease-out
      );

      &-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        padding: 1px $gutter-space;
      }

      &-indicator {
        width: 100%;
        padding: $gutter-space / 2 0;
        border-bottom: 1px solid rgba(197, 197, 197, .4);
        font-weight: normal;
        visibility: visible;
      }

      &-items {
        position: absolute;
        top: -100em;
        left: 0;
        right: 0;
        margin: 0 $gutter-space;
        flex-direction: column;
        justify-content: center;
      }

      &-item {
        display: block;
        padding: 9px 0;
        border-bottom: 1px solid rgba(197, 197, 197, .4);
        margin: 0;
        color: $color-black !important;
        font-size: 17px;
        line-height: 27px;
        text-align: center;
        opacity: 0;
        @include transform(translateY(-20px));

        &:hover, &.active { color: $color-black; }
        &:first-child { border-top: 1px solid rgba(197, 197, 197, .4); }
      }

      @for $i from 0 to 10 {
        &-item:nth-child(#{$i}n) {
          @include transition(
            opacity .1s ease #{(10 - $i) * .03}s,
            transform .1s ease #{(10 - $i) * .03}s
          );
        }
      }

      &-toggle { display: flex; }

      &.open {
        background-color: $color-white;
        @include transition(
          height .3s cubic-bezier(.645, .045, .355, 1),
          background-color .2s ease-out
        );

        .NavigationSub {
          &-toggle {
            .icon {
              &-close {
                opacity: 1;
                visibility: visible;
                @include transition(
                  opacity .1s ease-out,
                  visibility .1s ease-out .1s
                );
              }

              &-open {
                opacity: 0;
                visibility: hidden;
                @include transition(
                  opacity .1s ease-out,
                  visibility .1s ease-out .1s
                );
              }
            }
          }

          &-indicator { border-bottom: none; }

          &-items {
            top: 54px;
            @include transition(top 0s cubic-bezier(.645, .045, .355, 1) 0s);
          }

          &-item {
            opacity: 1;
            @include transform(translateY(0));
          }

          @for $i from 0 to 10 {
            &-item:nth-child(#{$i}n) {
              @include transition(
                opacity .1s ease #{.1 + ($i) * .03}s,
                transform .1s ease #{.1 + ($i) * .03}s
              );
            }
          }
        }
      }

      &.sticky {
        position: fixed;
        top: 0;
        margin-top: 0;
        background-color: $color-white;
        color: $color-black;

        .NavigationSub-indicator { border-bottom: none; }
      }
    }
  }
}
