.App {
  position: relative;
  width: 100vw;
}

.Page,
section.Component,
.PageTeaser {
  z-index: 0;

  &.theme {
    &-bright,
    &-grey,
    &-grey-light,
    &-white {
      color: $color-black;

      a {
        &, &:active, &:visited { color: $color-blue; }

        &.button-primary {
          background-color: $color-blue;
          color: $color-white;
        }

        &.button-secondary { color: $color-blue; }
        
        .icon {
          &-arrow { fill: $color-blue; }
        }
        
        h1, h2, h3, h4, h5 { color: $color-black; }
      }
      
      span {
        &.button-primary {
          background-color: $color-blue;
          color: $color-white;
        }

        &.button-secondary { color: $color-blue; }
      }

      .headline-framed {
        border: 1px solid rgba(51, 51, 51, .2);
        color: $color-black;
      }

      .info-text { color: $color-grey-dark; }

      .PageSlider {
        &-arrow {
          border: none;
          background-color: rgba(255, 255, 255, .4);
          fill: $color-black;

          &:hover { background-color: rgba(255, 255, 255, .6); }
        }

        &-nav {
          border-bottom: 1px solid $color-grey-light;

          ul li.active::after { background-color: $color-black; }
        }
      }

      .PageColumns {
        .bubble {
          background-color: $color-white;
          box-shadow: 0 42px 66px rgba(26, 26, 26, 0.15);
        }
        .bullet .bullet-icon { fill: $color-blue; }
        .divider { opacity: 1; }
        .learn-more .icon { fill: $color-grey-dark; }
        .link .link-icon { fill: $color-blue; }
      }

      .slick-dots li {
        button {
          &:before { color: $color-grey-light; }

          &:hover, &:focus {
            &:before { color: $color-black; }
          }
        }

        &.slick-active button:before {
          border: 1px solid $color-black;
          color: $color-black;
        }
      }
    }

    &-bright {
      background-color: $color-grey-background;

      .gradient-top::before { background-image: linear-gradient(rgba(240, 240, 240, 1) 0%, rgba(240, 240, 240, 0) 20%); }
      .gradient-bottom::after { background-image: linear-gradient(rgba(240, 240, 240, 1) 80%, rgba(240, 240, 240, 0) 100%); }
      .gradient-horizontal::before { background-image: linear-gradient(90deg, rgba(240, 240, 240, 1) 0%, rgba(240, 240, 240, 0) 20%, rgba(240, 240, 240, 0) 80%, rgba(240, 240, 240, 1) 100%); }
      .gradient-vertical::after { background-image: linear-gradient(rgba(240, 240, 240, 1) 0%, rgba(240, 240, 240, 0) 20%, rgba(240, 240, 240, 0) 80%, rgba(240, 240, 240, 1) 100%); }

      .PageLoadingIndicator { background-color: $color-grey-background; }
    }

    &-dark {
      background-color: $color-black-background;
      color: $color-white;

      a {
        &, &:active, &:visited { color: $color-white; }

        &.button-primary {
          background-color: $color-white;
          color: $color-blue;
        }

        &.button-secondary { color: $color-white; }
        
        .icon {
          &-arrow { fill: $color-white; }
        }
        
        h1, h2, h3, h4, h5 { color: $color-white; }
      }
      
      span {
        &.button-primary {
          background-color: $color-white;
          color: $color-blue;
        }

        &.button-secondary { color: $color-white; }
      }

      .gradient-top::before { background-image: linear-gradient(rgba(1, 1, 17, 1) 0%, rgba(1, 1, 17, 0) 20%); }
      .gradient-bottom::after { background-image: linear-gradient(rgba(1, 1, 17, 1) 80%, rgba(1, 1, 17, 0) 100%); }
      .gradient-horizontal::before { background-image: linear-gradient(90deg, rgba(1, 1, 17, 1) 0%, rgba(1, 1, 17, 0) 20%, rgba(1, 1, 17, 0) 80%, rgba(1, 1, 17, 1) 100%); }
      .gradient-vertical::after { background-image: linear-gradient(rgba(1, 1, 17, 1) 0%, rgba(1, 1, 17, 0) 20%, rgba(1, 1, 17, 0) 80%, rgba(1, 1, 17, 1) 100%); }

      .headline-framed {
        border: 1px solid rgba(255, 255, 255, .3);
        background-color: rgba(255, 255, 255, .07);
        color: rgba(255, 255, 255, .4);
      }

      .info-text { color: $color-grey-medium; }

      .PageLoadingIndicator { background-color: $color-black-background; }

      .PageSlider {
        &-arrow {
          border: 1.2px solid rgba(255, 255, 255, .2);
          fill: $color-white;

          &:hover { border-color: rgba(255, 255, 255, .4); }
        }

        &-nav {
          border-bottom: 1px solid rgba(255, 255, 255, .15);

          ul li.active::after { background-color: $color-white; }
        }
      }

      .PageColumns {
        .bubble { background-color: $color-grey-background; }
        .bullet .bullet-icon { fill: $color-white; }
        .divider { opacity: .4; }
        .learn-more .icon { fill: $color-grey-light; }
        .link .link-icon { fill: $color-white; }
      }

      .slick-dots li {
        button {
          &:before {
            color: $color-white;
            opacity: .4;
          }

          &:hover, &:focus {
            &:before { opacity: 1; }
          }
        }

        &.slick-active button:before {
          border: 1px solid $color-white;
          color: $color-white;
          opacity: 1;
        }
      }
    }

    &-grey {
      background-color: $color-grey-light;

      .gradient-top::before { background-image: linear-gradient(rgba(197, 197, 197, 1) 0%, rgba(197, 197, 197, 0) 10%); }
      .gradient-bottom::after { background-image: linear-gradient(rgba(197, 197, 197, 1) 90%, rgba(197, 197, 197, 0) 100%); }
      .gradient-horizontal::before { background-image: linear-gradient(90deg, rgba(197, 197, 197, 1) 0%, rgba(197, 197, 197, 0) 10%, rgba(197, 197, 197, 0) 90%, rgba(197, 197, 197, 1) 100%); }
      .gradient-vertical::after { background-image: linear-gradient(rgba(197, 197, 197, 1) 0%, rgba(197, 197, 197, 0) 10%, rgba(197, 197, 197, 0) 90%, rgba(197, 197, 197, 1) 100%); }

      .PageLoadingIndicator { background-color: $color-grey-light; }
    }

    &-grey-light {
      background-color: $color-grey-light2;

      .gradient-top::before { background-image: linear-gradient(rgba(225, 225, 225, 1) 0%, rgba(225, 225, 225, 0) 20%); }
      .gradient-bottom::after { background-image: linear-gradient(rgba(225, 225, 225, 1) 80%, rgba(225, 225, 225, 0) 100%); }
      .gradient-horizontal::before { background-image: linear-gradient(90deg, rgba(225, 225, 225, 1) 0%, rgba(225, 225, 225, 0) 20%, rgba(225, 225, 225, 0) 80%, rgba(225, 225, 225, 1) 100%); }
      .gradient-vertical::after { background-image: linear-gradient(rgba(225, 225, 225, 1) 0%, rgba(225, 225, 225, 0) 20%, rgba(225, 225, 225, 0) 80%, rgba(225, 225, 225, 1) 100%); }

      .PageLoadingIndicator { background-color: $color-grey-light2; }
    }

    &-white {
      background-color: $color-white;

      .gradient-top::before { background-image: linear-gradient(rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 10%); }
      .gradient-bottom::after { background-image: linear-gradient(rgba(255, 255, 255, 1) 90%, rgba(255, 255, 255, 0) 100%); }
      .gradient-horizontal::before { background-image: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 0) 90%, rgba(255, 255, 255, 1) 100%); }
      .gradient-vertical::after { background-image: linear-gradient(rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 0) 90%, rgba(255, 255, 255, 1) 100%); }

      .PageLoadingIndicator { background-color: $color-white; }
    }
  }
}

.Page {
  padding-top: 240px;

  @include media-query($small-down) {
    padding-top: 48px;
  }
}

.Component {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;

  &-wrapper {
    position: relative;
    width: 100%;

    &.width-xl {
      max-width: $xxlarge-viewport;

      .gradient-horizontal::before {
        display: none;

        @include media-query($widescreen) {
          display: block;
        }
      }
    }

    &.width-lg {
      max-width: $xlarge-viewport;

      .gradient-horizontal::before {
        display: none;

        @include media-query($xlarge-up) {
          display: block;
        }
      }
    }

    &.width-md { max-width: $large-viewport; }
    &.width-sm { max-width: $medium-viewport; }
  }
}
