.Footer {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 90px 0 60px;
  background-color: $color-black-background;
  color: $color-white;
  font-size: 17px;
  line-height: 27px;

  @include media-query($small-down) {
    padding: 30px 0;
  }

  a, a:visited {
    color: $color-white;
    text-decoration: none;

    &:hover, &.active {
      opacity: .4;
      @include transition(opacity .25s ease-in-out);
    }
  }

  hr {
    margin: $gutter-space * 2 0;
    opacity: .3;
  }

  &-nav-col {
    display: flex;
    flex-direction: column;
    padding-right: $gutter-space;
    
    @include media-query($large-down) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  a.Footer-social-media,
  .Footer-social-media {
    display: inline-block;
    height: 24px;
    margin: 0 18px $gutter-space 0;
    opacity: .4;
    @include transition(opacity .25s ease-in-out);
    @include hover-fix;

    &:hover { opacity: 1; }

    svg { fill: $color-white }
    &-facebook, &-linkedin { width: 24px; }

    &-youtube {
      width: 30px;
      margin-left: 3px;
      transform: translateY(2px);
    }
  }
}
