@font-face {
  font-family: "LufthansaHead";
  font-style: normal;
  font-weight: bold;
  src:
    url("./fonts/LufthansaHeadWeb-Bold.woff2") format("woff2"),
    url("./fonts/LufthansaHeadWeb-Bold.woff") format("woff"),
    url("./fonts/LufthansaHeadWeb-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "LufthansaHead";
  font-style: normal;
  font-weight: 100;
  src:
    url("./fonts/LufthansaHeadWeb-Light.woff2") format("woff2"),
    url("./fonts/LufthansaHeadWeb-Light.woff") format("woff"),
    url("./fonts/LufthansaHeadWeb-Light.ttf") format("truetype");
}

@font-face {
  font-family: "LufthansaText";
  font-style: normal;
  font-weight: bold;
  src:
    url("./fonts/LufthansaTextWeb-Bold.woff2") format("woff2"),
    url("./fonts/LufthansaTextWeb-Bold.woff") format("woff"),
    url("./fonts/LufthansaTextWeb-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "LufthansaText";
  font-style: normal;
  font-weight: normal;
  src:
    url("./fonts/LufthansaTextWeb-Regular.woff2") format("woff2"),
    url("./fonts/LufthansaTextWeb-Regular.woff") format("woff"),
    url("./fonts/LufthansaTextWeb-Regular.ttf") format("truetype");
}
