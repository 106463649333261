.CaseStudies {
  padding: 180px 0;

  @include media-query($small-down) {
    padding: $gutter-space * 5 0;
  }
  
  h1 { margin-bottom: 90px; }
}

.CaseStudyAudio {
  .PageHeader {
    &-image {
      top: -30px;
      right: 30px;
      width: 660px;
      
      @include media-query($medium-down) {
        width: 60%;
      }

      @include media-query($small-down) {
        top: 0;
        right: 60px;
        display: block;
        width: 72%;
      }
    }
    
    @include media-query($small-down) {
      .content { margin-top: 84%; }
    }
  }
  
  .PageSlider {
    &.text { margin: $gutter-space * 3 auto 0; }
    
    .slick-dots { visibility: visible; }
  }
}

.CaseStudyDial {
  .PageHeader {
    &-image {
      top: -100px;
      width: 80%;
      right: -5%;
      
      @include media-query($medium-down) {
        top: -150px;
        width: 80%;
      }

      @include media-query($small-down) {
        top: 60px;
        right: 0;
        display: block;
        width: 100%;
      }
    }
    
    @include media-query($small-down) {
      .content { margin-top: 84%; }
    }
  }
  
  .PageSlider {
    &.text { margin: $gutter-space * 3 auto 0; }
    
    .slick-dots { visibility: visible; }
  }
}

.Contact {
  .PageHeader {
    @include media-query($large-down) {
      margin-top: -60px;
    }

    @include media-query($small-down) {
      margin-top: 0;
    }
  }

  .component-1 {
    @include media-query($small-down) {
      margin-top: $gutter-space * 2;
    }
  }

  .component-4 {
    .column-0.image {
      @include media-query($small-down) {
        width: 180%;
        margin-left: -10%;
      }

      img { width: 100%; }
    }
  }

  section:nth-child(5) {
    @include media-query($large-up) {
      margin-top: -240px;
    }

    @include media-query($large-down) {
      margin-top: -60px;
    }
  }
}

.CustomerSupport,
.Lifecycle,
.LifecycleC300,
.LifecycleC350,
.LifecycleTraining {
  .PageHeader {
    @include media-query($medium-down) {
      margin-top: -60px;
    }

    @include media-query($small-down) {
      margin-top: $gutter-space * 3;
    }
  }
}

.FutureFaceOfNice {
  padding-top: 180px;

  @include media-query($small-down) {
    padding-top: $gutter-space * 5;
  }
  
  .component {
    &-4 {
      .image-layered-layer-0 {
        top: 210px;
      }
      
      .image-layered-layer-1 {
        width: 20%;
      }
      
      .image-layered {
        &-layer-0 { top: 35vw; }
        &-layer-1 { width: 50vw; }
        
        @include media-query($large-up) {
          &-layer-0 { top: 210px; }
          &-layer-1 { width: 360px; }
        }
      }
    }
    
    &-5 {
      margin-top: 720px;
      
      @include media-query($large-down) {
        margin-top: 90vw;
      }
      
      .BackgroundGradient {
        @include media-query($medium-down) {
          display: none;
        }
      }
      
      .column-1.image {
        width: 900px;
        margin-left: 120px;
        
        @include media-query($medium-down) {
          width: 600px;
          margin-left: 20vw;
        }
        
        @include media-query($small-down) {
          width: 100%;
          margin-left: 90px;
        }
      }
    }
    
    &-7 {
      .PageColumns.image,
      .PageColumns.subTitle {
        display: inline-block;
      }
      
      .PageColumns.image {
        margin-right: 18px;
        vertical-align: middle;
      }
    }
    
    &-8 {
      .image-layered {
        &-layer-0 { top: 9vw; }
        &-layer-1 {
          top: 6vw;
          width: 95%;
        }
        
        @include media-query($large-up) {
          &-layer-0 { top: 150px; }
          &-layer-1 { top: 100px; }
        }
      }
    }
    
    &-9 {
      margin-top: 480px;
      
      @include media-query($large-down) {
        margin-top: 35vw;
      }
    }
  }
  
  
}

.Home {
  @include media-query($small-up) {
    &.Page {
      padding-top: 110px;
    }
  }

  .PageHeader {
    .Component-wrapper {
      height: 100vh;

      @include media-query($medium-down) {
        height: auto;

        .content {
          position: relative;
          padding-top: $padding-lg;
          padding-bottom: $padding-xl;
          top: 58%;
        }
      }

      @include media-query($small-down) {
        .content {
          position: relative;
          top:0;
          transform: none;
          -webkit-transform:none;
          -ms-transform:none;
          margin-top: -86%;
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }

    .content {
      max-width: $xlarge-viewport;
      position: absolute;
      top: 50%;
      left: 0;
      right:0;
      margin-left:auto;
      margin-right:auto;
      -webkit-transform: perspective(1px) translateY(-50%);
      -ms-transform: perspective(1px) translateY(-50%);
      transform: perspective(1px) translateY(-50%);
      margin-top: -6vh;

      @include media-query($small-down) {
        margin-top: auto;
        padding-bottom: $padding-xs;
      }
    }

    &-video { margin-top: -20px; }

    .video-overlay-dark {
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  .component-3 {
    .column-1.image {
      width: 887px;

      @include media-query($medium-down) {
        width: 100%;
      }
    }
  }

  .component-4 {
    .column-0.image {
      width: 720px;
      margin-left: -300px;

      @include media-query($medium-down) {
        width: 100% !important;
        margin: 0;
      }
    }
  }

  .component-5 { padding-bottom: 150px; }

  .component-6 {
    .column-0.image {
      margin-top: -150px;
    }
  }

  .component-7 { margin-bottom: -48px; }
}

.Lifecycle {
  .component-11 {
    .column-0.image {
      width: 480px;
      margin-left: -120px;

      @include media-query($medium-down) {
        width: 100% !important;
        margin: 0 !important;
        box-shadow: none;
      }
    }
  }

  .component-11 {
    .column-1.image {
      width: 720px;
      margin-left: 90px;
      box-shadow: 0 33px 77px rgba(1, 1, 17, 0.44);

      @include media-query($medium-down) {
        width: 100%;
        margin: 0;
        box-shadow: none;
      }
    }
  }
}

.LifecycleC300 {
  .component-2 {
    .column-0.image,
    .column-1.image {
      padding: 0 $gutter-space / 2;
    }
  }
}

.LifecycleC350 {
  .component-5 {
    .column-0,
    .column-2 {
      display: flex;
      flex-direction: column;
      z-index: 100;
    }

    .column-0 { align-items: flex-start; }
    .column-2 { align-items: flex-end; }

    @include media-query($medium-down) {
      .column-0,
      .column-2 {
        display: block;
      }
    }

    .column-0.bubble,
    .column-2.bubble {
      &:first-child { margin-top: $gutter-space * 3 }

      &:nth-child(1) { width: 360px; }
      &:nth-child(2) { width: 300px; }
      &:nth-child(3) { width: 270px; }

      @include media-query($large-down) {
        &:first-child { margin-top: $gutter-space * 3 }

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          width: 100%;
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
      }

      @include media-query($medium-down) {
        &:first-child { margin-top: 0; }
      }
    }

    .column-0.bubble {
      &:nth-child(2) { margin-left: $gutter-space; }
      &:nth-child(3) { margin-left: $gutter-space * 4; }
    }

    .column-2.bubble {
      &:nth-child(2) { margin-right: $gutter-space; }
      &:nth-child(3) { margin-right: $gutter-space * 4; }
    }

    .column-1.image img {
      width: 60%;
      box-shadow: 0 42px 66px rgba(26, 26, 26, 0.15);

      @include media-query($large-down) {
        width: 100%;
      }

      @include media-query($medium-down) {
        width: 60%;
        margin-bottom: $gutter-space * 2;
        box-shadow: none;
      }
    }
  }

  .component-6 {
    .PageColumns {
      .column-0.video-framed {
        width: 1020px;
        margin-left: -450px;

        @include media-query($medium-down) {
          width: 100% !important;
          margin: 0;
        }
      }

      .column-1.image {
        width: 90px;

        @include media-query($medium-down) {
          width: 60px;
        }
      }
    }
  }
}

.LifecycleTraining {
  .component-8 {
    .column-0.image {
      width: 720px;
      margin-left: -180px;
      box-shadow: 0 33px 77px rgba(1, 1, 17, 0.44);

      @include media-query($medium-down) {
        width: 100% !important;
        margin: 0;
        box-shadow: none;
      }
    }
  }
}

.Insights {
  min-height: 100vh;
  padding-top: 180px;

  @include media-query($small-down) {
    padding-top: $gutter-space * 5;
  }
}

.News {
  min-height: 100vh;
  padding-top: 180px;

  @include media-query($small-down) {
    padding-top: $gutter-space * 4;
  }
}

.NewsArticle {
  $content-width: 720px;
  
  min-height: 100vh;

  @include media-query($small-down) {
    padding-top: $gutter-space * 4;
  }
  
  &-content,
  &-footer,
  &-meta {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  &-content {
    h2, h3, h4,
    p, blockquote, ul {
      width: 100%;
      max-width: $content-width;
    }
    
    h2, h3 {
      margin: 60px 0 30px;
      
      @include media-query($small-down) {
        margin: 30px 0 18px;
      }
    }
    
    h4 {
      margin: 60px 0 24px;
      
      @include media-query($small-down) {
        margin: 30px 0 12px;
      }
    }
    
    p, ul {
      margin-bottom: 30px;
      
      &:last-child { margin-bottom: 0; }
    }
    
    p.image {
      max-width: 100%;
    }
    
    p.video {
      position: relative;
      display: block;
      height: 0;
      max-width: 100%;
      padding-top: 30px;
      padding-bottom: 56.25%;
      margin: 60px 0 30px;
      overflow: hidden;
      
      @include media-query($small-down) {
        margin: 30px 0 18px;
      }

      iframe,
      object,
      embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    
    ul {
      margin-left: 66px;
      
      li {
        padding-left: 12px;
        padding-right: 24px;
        margin-bottom: 12px;
        
        &:last-child { margin-bottom: 0; }
      }
    }
    
    blockquote {
      border-left: 3px solid $color-blue;
      padding-left: 24px;
      margin: 12px 0 42px;
      color: $color-blue;
      font-size: 26px;
      line-height: 36px;
      box-sizing: border-box;
      
      @include media-query($small-down) {
        margin: 12px 0 24px;
      }
      
      &:last-child { margin-bottom: 0; }
    }
    
    img {
      width: 100%;
      margin-bottom: 72px;
      
      @include media-query($small-down) {
        margin-bottom: 36px;
      }
    }
    
    .image-caption {
      margin: -84px 0 72px;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      opacity: .6;
      
      @include media-query($small-down) {
        margin-bottom: 36px;
        margin: -42px 0 36px;
      }
    }
  }
  
  &-footer {
    margin: 90px 0;
    
    @include media-query($small-down) {
      margin: 60px 0;
    }
    
    &-wrapper {
      width: 100%;
      max-width: $content-width;
    }
  }
  
  &-meta {
    h1,
    .NewsMeta {
      width: 100%;
      max-width: $content-width;
    }
    
    h1 {
      margin: 0 0 120px;
      
      @include media-query($small-down) {
        margin: 0 0 60px;
      }
    }
  }
}

.Philosophy {
  .PageHeader {
    @include media-query($large-down) {
      margin-top: -120px;
    }

    @include media-query($small-down) {
      margin-top: 0;
    }

    .Component-wrapper {
      height: 600px;

      @include media-query($large-down) {
        height: auto;
      }
    }

    .content {
      @include media-query($large-up) {
        margin-top: -60px;
      }
    }

    &-image {
      top: -21%;

      @include media-query($xlarge-up) {
        top: -290px;
        width: 112%;
        margin-left:-7%;
      }

      @include media-query($large-down) {
        top: 60px;
      }
    }

    &-image-mobile {
      margin-bottom: -65%;
      margin-top: -40px;
    }
  }

  .component-2 {
    @include media-query($large-down) {
      margin-top: 120px;
    }

    @include media-query($small-down) {
      margin-top: 0;
    }

    .video-teaser {
      @include media-query($small-down) {
        width: 200%;
        margin-left: -50%;
      }
    }
  }

  .component-4 {
    .column-0.sectionTitle {
      margin-top: 30px;
      font-size: 20px;
      line-height: 27px;
      letter-spacing: normal;
      text-transform: none;
    }
  }
}

.System {
  .PageHeader {
    .content {
      margin-top: 60px;

      @include media-query($small-down) {
        margin-top: $gutter-space * 5;
      }
    }
  }
}

.SystemAudio {
  .PageHeader {
    &-image {
      top: -180px;
      left: -430px;
      width: 900px;

      @include media-query($medium-down) {
        left: -500px;
      }
    }

    @include media-query($small-down) {
      &-image-mobile {
        margin-bottom: -42%;
      }
    }
  }

  @include media-query($small) {
    .component-2 {
      margin-bottom: ($padding-xxl + 60px) * -1;
    }
  }
}

.SystemConnectivity {
  .PageHeader {

    @include media-query($large-up) {
      &-image {
        top: 210px;
        z-index: -1000;
      }
    }

    @include media-query($large-down) {
      &-image {
        top: -300px;
        position:relative;
        margin-bottom:-400px;
        z-index: -1000;
      }
    }

    @include media-query($medium-down) {
      &-image {
        top: -130px;
        position:relative;
        margin-bottom:-220px;
        z-index: -1000;
      }
    }

    @include media-query($small-down) {
      position: relative;
    }

    @include media-query($large-up) {
      .content {
        margin-top: 30px;
        margin-bottom: 20%;
      }
    }

    @include media-query($small-down) {
      &-image-mobile {
        margin-bottom: -50%;
      }
    }
  }
}

.SystemMedia {
  .PageHeader {
    &-image {
      top: -90px;
      left: -420px;
      width: 80%;

      @include media-query($medium-down) {
        position: relative;
        margin-top: -90px;
        margin-bottom: -70px;
        top: auto;
        left:-90px;
        width:90%;
      }
    }

    @include media-query($small-down) {
      &-image-mobile {
         position: relative;
         margin-bottom: -22%;
         width: 110%;
         margin-left: -2.5%;
         margin-top: 48px;
      }
    }

    .content {
      margin-top: 60px;

      @include media-query($medium-down) {
        margin-left: 0;
      }

      @include media-query($small-down) {
        margin-top: -60px;
      }
    }

    @include media-query($small-down) {
      .BackgroundGradient {
         display: none;
      }
    }
  }
}

.SystemPEDs {
  .PageHeader {
    .content {
      @include media-query($small-down) {
        margin-top: 120px;
      }
    }
  }

  .component-5 {
    .column-0,
    .column-2 {
      display: flex;
      flex-direction: column;
    }

    .column-0 { align-items: flex-start; }
    .column-2 { align-items: flex-end; }

    @include media-query($medium-down) {
      .column-0,
      .column-2 {
        display: block;
      }
    }

    .column-0.bubble,
    .column-2.bubble {
      &:first-child { margin-top: $gutter-space * 5 }

      &:nth-child(1) { width: 360px; }
      &:nth-child(2) { width: 300px; }
      &:nth-child(3) { width: 270px; }

      @include media-query($large-down) {
        &:first-child { margin-top: $gutter-space * 3 }

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          width: 100%;
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
      }

      @include media-query($medium-down) {
        &:first-child { margin-top: 0; }
      }
    }

    .column-0.bubble {
      &:nth-child(2) { margin-left: $gutter-space; }
      &:nth-child(3) { margin-left: $gutter-space * 4; }
    }

    .column-2.bubble {
      &:nth-child(2) { margin-right: $gutter-space; }
      &:nth-child(3) { margin-right: $gutter-space * 4; }
    }
  }

  .component-6 {
    .column-1.image {
      width: 887px;

      @include media-query($large-down) {
        width: 100%;
      }
    }
  }

  .component-8 {
    .column-0.image {
      width: 887px;
      margin-left: -320px;

      @include media-query($large-down) {
        width: 100%;
        margin-left: 0;
      }
    }
  }
}

.SystemUserInterface {
  .PageHeader {

    @include media-query($large-up) {
      .video-copy-wrapper {
        margin-top:10%;
        margin-bottom:10%;
      }
    }

    @include media-query($large-down) {
      .video-copy-wrapper {
        margin-top:36px;
        margin-bottom:5%;
      }
    }

    @include media-query($medium-down) {
      .video-copy-wrapper {
        margin-bottom:0;
      }
    }

    &-video { top: -120px; }
  }

  .component-2 {
    .column-1.image {
      width: 240px;
      margin: $gutter-space * 2 0;
    }
  }

  .component-6 {
    padding-bottom: 170px;
    z-index: 0;

    @include media-query($medium) {
      padding-bottom: 75px;
    }

    @include media-query($small-down) {
      padding-bottom: 36px;
    }

  }

  .component-7 {
    .column-0.image {
      margin-top: -240px;

      @include media-query($small-down) {
        margin-top: -120px;
      }
    }
  }
}

.SystemVideo {
  .PageHeader {
    @include media-query($small-up) {
      margin-top: -120px;
    }

    &-image {
      top: -120px;
      left: 50%;
      right: 0;
      width: 75%;
      margin-top: 240px;
    }

    @include media-query($large-down) {
      &-image {
        left: 60%;
      }
    }

    @include media-query($medium-down) {
      .BackgroundGradient {
        top:-110px;
      }
    }

    .content {
      @include media-query($small-up) {
        margin-top: 180px;
      }
    }

    @include media-query($small-down) {
      .BackgroundGradient {
        display: none;
      }
    }
  }

  .PageContent {
    &.component {
      &-2 {
        @include media-query($medium) {
          margin-top: -8%;
        }
      }
      
      &-4 {
        .PageColumns.image,
        .PageColumns.subTitle {
          display: inline-block;
        }
        
        .PageColumns.image {
          margin-right: 18px;
          vertical-align: middle;
        }
      }
      
      &-5 {
        .image-layered {
          &-layer-0 { top: 9vw; }
          &-layer-1 {
            top: 6vw;
            width: 95%;
          }
          
          @include media-query($large-up) {
            &-layer-0 { top: 150px; }
            &-layer-1 { top: 100px; }
          }
        }
      }
      
      &-6 {
        margin-top: 480px;
        
        @include media-query($large-down) {
          margin-top: 35vw;
        }
      }
    }
  }
}
