html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  background-color: $color-black-background;
  font-family: "LufthansaText", sans-serif;
  font-size: 20px;
  font-weight: normal;
  line-height: 27px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;

  @include media-query($small-down) {
    font-size: 17px;
    line-height: 24px;
  }
}

a {
  &, &:active, &:visited {
    display: block;
    text-decoration: none;
  }
  
  &:hover { text-decoration: underline; }
  
  &.button-primary {
    display: inline-block;
    @include border-radius(3px);
    margin: 24px 0;
    font-size: 16px;
    font-weight: bold;
    line-height: 18px;
    letter-spacing: .2px;
    text-decoration: none;
  }

  &.button-secondary {
    text-decoration: none;

    &:hover { text-decoration: underline; }
  }

  &.button-lg { padding: 18px 24px; }
  &.button-md { padding: 9px 18px; }
  
  &.no-styling {
    &:hover { text-decoration: none !important; }
  }
  
  .icon {
    &-arrow {
      width: 18px;
      height: 18px;
      margin: 0 7px -2px 0;
      
      @include media-query($small-down) {
        width: 16px;
      }
    }
  }
}

hr {
  height: 1px;
  width: 100%;
  border: none;
  margin: 0;
  background-color: $color-grey-light;
  z-index: 200;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

span {
  &.button-primary,
  &.button-secondary {
    cursor: pointer;
    text-decoration: none;
  }
    
  &.button-primary {
    display: inline-block;
    @include border-radius(3px);
    margin: 24px 0;
    font-size: 16px;
    font-weight: bold;
    line-height: 18px;
    letter-spacing: .2px;
  }

  &.button-secondary {
    &:hover { text-decoration: underline; }
  }

  &.button-lg { padding: 18px 24px; }
  &.button-md { padding: 9px 18px; }
}

strong { font-weight: bold; }

h1,
h2,
h3 {
  font-family: "LufthansaHead", sans-serif;
  font-weight: bold;
  white-space: pre-wrap;
}

h4,
h5 {
  font-family: "LufthansaText", sans-serif;
  font-weight: bold;
  white-space: pre-wrap;
}

.headline-xl {
  margin-bottom: $gutter-space * 2;
  font-size: 84px;
  line-height: 86px;

  @include media-query($medium-down) {
    font-size: 52px;
    line-height: 54px;
  }

  @include media-query($small-down) {
    margin-bottom: 24px;
    font-size: 38px;
    line-height: 42px;
  }
}

h1,
.headline-lg {
  margin-bottom: $gutter-space * 1.25;
  font-size: 52px;
  line-height: 54px;

  @include media-query($medium-down) {
    margin-bottom: 16px;
    font-size: 32px;
    line-height: 36px;
  }
}

h2,
.headline-md {
  margin-bottom: $gutter-space * 1.25;
  font-size: 40px;
  line-height: 48px;

  @include media-query($small-down) {
    margin-bottom: $gutter-space;
    font-size: 26px;
    line-height: 30px;
  }
}

h3,
.headline-sm {
  margin-bottom: $gutter-space * .5;
  font-size: 24px;
  line-height: 30px;

  @include media-query($small-down) {
    font-size: 18px;
    line-height: 24px;
  }
}

h4,
.headline-xs {
  margin-bottom: $gutter-space * .75;
  font-size: 17px;
  line-height: 24px;

  @include media-query($small-down) {
    font-size: 14px;
    line-height: 18px;
  }
}

h5,
.headline-xxs {
  margin-bottom: $gutter-space * .75;
  font-size: 14px;
  letter-spacing: 1.3px;
  line-height: 18px;
  text-transform: uppercase;

  @include media-query($small-down) {
    font-size: 12px;
    line-height: 12px;
  }
}

.headline {
  &-framed {
    display: inline-block;
    padding: 6px 6px 5px;
    @include border-radius(4px);

    &.blue {
      border: 1px solid rgba(181, 197, 253, .35) !important;
      color: rgba(181, 197, 253, .9) !important;
      background-color: rgba(33, 43, 77, .3) !important;
    }

    &.yellow {
      border: 1px solid rgba(255, 173, 0, .55) !important;
      color: rgba(255, 173, 0, .9) !important;
      background-color: rgba(255, 173, 0, .1) !important;
    }
  }
  
  &-info {
    margin: 0 0 3px !important;
    font-family: "LufthansaText";
    font-weight: normal;
    font-size: 18px;
    line-height: 30px; 
  }

  &-thin {
    margin: 0 0 30px;
    font-family: "LufthansaText";
    font-weight: normal;
    font-size: 38px;
    line-height: 48px;
  }
}

p, ul {
  display: block;

  &:last-child { margin-bottom: 0; }
}

p {
  margin: 0 0 $gutter-space;
  white-space: pre-wrap;
  
  &.copy {
    &-xl {
      margin: 0 0 $gutter-space * 1.25;
      font-family: "LufthansaHead", sans-serif;
      font-weight: 100;
      font-size: 52px;
      line-height: 54px;

      @include media-query($medium-down) {
        margin-bottom: 16px;
        font-size: 32px;
        line-height: 36px;
      }
    }

    &-lg {
      margin: 0 0 $gutter-space * 2;
      font-size: 38px;
      line-height: 48px;

      @include media-query($small-down) {
        margin: 12px 0;
        font-size: 24px;
        line-height: 30px;
      }
    }

    &-md {
      margin: 0 0 $gutter-space * .5;
      font-size: 26px;
      line-height: 36px;

      @include media-query($small-down) {
        margin: 12px 0;
        font-size: 20px;
        line-height: 24px;
      }
    }

    &-sm {
      font-size: 17px;
      line-height: 27px;

      @include media-query($small-down) {
        font-size: 15px;
        line-height: 21px;
      }
    }

    &-bold { font-weight: bold; }
  }
  
  &.company-logo {
    display: inline-block;
    padding: 24px 36px;
    border: 2px solid $color-grey-light2;
    @include border-radius(10px);
    margin: $gutter-space 0;
    font-family: "LufthansaHead", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
  }
}

ul {
  margin: 0 0 $gutter-space $gutter-space * 2;
  list-style: disc;
}

.error-message {
  color: $color-red;
  opacity: 1;
}

.info-text {
  font-size: 15px;
  line-height: 18px;
}

.text {
  &-bold { font-weight: bold; }
  
  &-date {
    color: $color-grey-medium;
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 1.59px;
    line-height: 24px;
    text-transform: uppercase;
    
    @include media-query($small-down) {
      font-size: 12px;
      line-height: 18px;
    }
  }
  
  &-left {
    text-align: left;
    
    &-medium {
      @include media-query($medium-up) {
        text-align: left !important;
      }
    }
    
    &-mobile {
      @include media-query($medium-down) {
        text-align: left !important;
      }
    }
  }
  
  &-right {
    text-align: right;

    &-medium {
      @include media-query($medium-up) {
        text-align: right !important;
      }
    }

    &-mobile {
      @include media-query($medium-down) {
        text-align: right !important;
      }
    }
  }
  
  &-centered {
    text-align: center;

    &-medium {
      @include media-query($medium-up) {
        text-align: center !important;
      }
    }

    &-mobile {
      @include media-query($medium-down) {
        text-align: center !important;
      }
    }
  }

  &-quote {
    font-family: "LufthansaHead", sans-serif;
    font-weight: bold;
    font-size: 84px;
    line-height: 102px;
    
    @include media-query($medium-down) {
      font-size: 78px;
      line-height: 96px;
    }
  }

  &-shadow { text-shadow: 4px 3px 8px rgba(51, 51, 51, .4); }
}

.container-fluid {
  @include media-query($large-down) {
    padding-left: $gutter-space !important;
    padding-right: $gutter-space !important;
  }
}

.extraspace {
  @include media-query($large-up) {
    &-left { padding-left: $gutter-space * 4; }
    &-right { padding-right: $gutter-space * 4; }
  }
}

.full-width-image img {
  width: 100%;
}

.gutter {
  padding-left: $gutter-space + 8px !important;
  padding-right: $gutter-space + 8px !important;
}

.gutter-sm {
  padding-left: 24px !important;
  padding-right: 24px !important;
  
  @include media-query($medium-down) {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
}

.no-gutter {
  margin-left: -$gutter-space !important;
  margin-right: -$gutter-space !important;
}

.gradient-top::before,
.gradient-bottom::after,
.gradient-horizontal::before,
.gradient-vertical::after {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  content: "";
  z-index: 100;
}

.no-overflow { overflow: hidden; }
