.BackgroundGradient {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: -100;

  &-item {
    position: absolute;
    background-repeat: no-repeat;
  }
}

.CaseStudyPreview {
  &-copy {
    @include media-query($small-up) {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: center;
    }
    
    @include media-query($small-down) {
      h2 {
        font-size: 32px;
        line-height: 42px;
      }
    }
    
    .text-date {
      display: block;
      padding-bottom: 12px;
    }
  }
  
  &-image {
    @include media-query($small-down) {
      margin-bottom: 36px;
    }
  }
}

.LoadingSpinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  
  &.full-height { height: 100vh; }
}

.News {
  &-file-attachment {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px 30px;
    margin-bottom: 90px;
    background-color: $color-grey-light2;
    
    @include media-query($small-down) {
      margin-bottom: 60px;
    }
    
    img { margin-right: 24px; }
  }
}

.NewsMeta {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  
  &-category {
    padding: 6px 12px;
    border-radius: 3px;
    margin-right: 6px;
    background-color: rgba(5, 22, 77, .2);
    color: $color-white !important;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.15px;
    line-height: 12px;
    text-decoration: none !important;
    
    &:last-of-type { margin-right: 12px; }
  }
}

.NewsPagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 90px 0 150px;
  
  @include media-query($small-down) {
    padding: 60px 0 90px;
  }
  
  &-wrapper {
    display: flex;
    align-items: center;
  }
  
  &-button {
    &-next, &-prev { cursor: pointer; }
    &-next img { transform: rotate(180deg); }
  }
  
  &-indicator {
    margin: 0 60px;
  }
}

.NewsPreview {
  &,
  &WithImage {
    padding: 60px 0;
    
    @include media-query($small-down) {
      padding: 42px 0;
    }
  }
  
  &WithImage {
    &-copy {
      @include media-query($small-up) {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;
      }
    }
    
    &-image {
      @include media-query($small-down) {
        margin-bottom: 36px;
      }
    }
  }
  
  &-link {
    color: $color-blue;
    
    &-icon {
      width: 18px;
      height: 18px;
      margin: 0 7px -2px 0;
      fill: $color-blue;
      
      @include media-query($small-down) {
        width: 16px;
      }
    }
  }
}

.NewsTeaser {
  &-headline {
    margin-bottom: 54px;
    font-family: "LufthansaText";
    font-size: 38px;
    font-weight: normal;
    line-height: 48px;
    
    @include media-query($medium-down) {
      margin-bottom: 30px;
      font-size: 30px;
      line-height: 36px;
    }
  }
  
  &-item {
    display: flex;
    
    @include media-query($medium-down) {
      margin-bottom: $gutter-space * 0.75;
      
      &:last-child { margin-bottom: 0; }
    }
    
    @include media-query($small-down) {
      margin-bottom: $gutter-space;
    }
    
    &-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 36px;
      background-color: $color-white;
    }
  }
}

.NewsTitleBar {
  margin-bottom: 90px;
  
  @include media-query($medium-down) {
    margin-bottom: $gutter-space * 2;
  }
  
  @include media-query($small-down) {
    margin-bottom: $gutter-space;
  }
  
  &-filter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    
    @include media-query($medium-down) {
      justify-content: flex-start;
    }
    
    &-info {
      margin-right: 12px;
      font-size: 15px;
      opacity: .6;
    }
    
    &-item {
      padding: 9px 12px;
      border-radius: 3px;
      margin-right: 12px;
      background-color: rgba(5, 22, 77, .2);
      color: $color-white !important;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.15px;
      line-height: 12px;
      text-decoration: none !important;
      cursor: pointer;
      
      &:last-child { margin-right: 0; }
      
      &.active {
        background-color: rgba(5, 22, 77, 1);
        cursor: default;
        pointer-events: none;
      }
    }
  }
  
  &-headline {
    margin: 0;
    font-family: "LufthansaText";
    font-size: 38px;
    font-weight: normal;
    line-height: 48px;
    
    @include media-query($medium-down) {
      margin-bottom: 30px;
    }
  }
}

.PageColumns {
  .bubble {
    padding: 18px;
    @include border-radius(18px);
    margin: $gutter-space 0;
    color: $color-black;
    text-align: left;
    box-sizing: border-box;

    &-copy { width: 100%; }

    &-title {
      width: 100%;
      margin-bottom: 0;
    }
  }

  .bullet {
    display: flex;
    flex-direction: row;
    width: 100%;

    &-text {
      width: 100%;
      margin: 0 $gutter-space $gutter-space * 2;

      @include media-query($small-down) {
        margin: 0 $gutter-space / 2 $gutter-space;
      }
    }

    &-icon.fact {
      margin-top: -5px;

      svg {
        height: 36px;
        width: 36px;
      }

      @include media-query($small-down) {
        margin-top: 0;

        svg {
          height: 24px;
          width: 24px;
        }
      }
    }

    &-icon.number {
      display: block;
      height: 36px;
      width: 36px;
      padding: 8px;
      @include border-radius(50%);
      margin-top: -4px;
      background-color: $color-blue;
      color: $color-white;
      font-family: "LufthansaHead";
      font-weight: bold;
      line-height: 20px;
      text-align: center;
      box-sizing: border-box;

      @include media-query($small-down) {
        margin-top: -6px;
      }
    }

    &.link {
      margin: 0;
    }
  }

  .contact {
    h4.contact-title {
      margin-bottom: 6px;
      font-size: 17px;
      font-weight: normal;
      line-height: 27px;
    }

    a.contact-text {
      display: inline-block;
      margin-bottom: $gutter-space * .75;
      color: $color-blue;
      font-family: "LufthansaHead", sans-serif;
      font-size: 24px;
      font-weight: bold;
      line-height: 30px;
      text-decoration: underline;

      @include media-query($small-down) {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }

  .image {
    &.full-width {
      img { width: 100%; }
    }
  }
  
  .image-layered {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    img { position: absolute; }
  }

  .image-slider .slick-dots { bottom: 30px; }

  .learn-more {
    display: inline-block;
    margin: $gutter-space 0 0;
    cursor: pointer;

    .icon {
      height: 24px;
      width: 24px;
      margin: 0 12px -8px 0;
      transform: rotate(90deg);
    }
  }

  .link {
    margin: 24px 0;

    &-icon {
      height: 18px;
      width: 18px;
      margin: 0 7px -2px 0;

      @include media-query($small-down) {
        height: 16px;
        width: 16px;
      }
    }
  }

  .list {
    margin-left: 0;
    font-size: 17px;
    list-style: none;
  }

  .video {
    video {
      width: 100%;

      @include media-query($small-down) {
        display: none;
      }
    }

    &-image-mobile {
      display: none;
      margin: 0 -$gutter-space;

      @include media-query($small-down) {
        display: block;
      }
    }
  }

  .video-framed {
    position: relative;
    width: 100%;

    video {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 85.1%;
      transform: translate(-50%, -50%);
      z-index: 100000;
    }
  }

  .video-teaser {
    position: relative;
    margin-bottom: $gutter-space;

    &-button {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      margin: auto;
      cursor: pointer;
      opacity: .85;
      @include transition(opacity .25s ease-in-out);

      &:hover {
        opacity: 1;
        @include transition(opacity .25s ease-in-out);
      }
    }
  }
  .bullet-list{
    list-style: disc;
    padding-left: 20px;
    font-size: 100%;
    li{
      margin-bottom: 0.7em;
    } 
  }
}

.PageDevices {
  &-images {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    @include media-query($medium-up) {
      justify-content: flex-end;
      height: 840px;
    }
  }

  &-image {
    &:nth-child(1), &:nth-child(3) { margin: $gutter-space 0 }
    &:nth-child(3) { width: 60% };

    @include media-query($medium-up) {
      position: absolute;

      &:nth-child(1) {
        top: 0px;
        left: -360px;
        width: 954px;
      }

      &:nth-child(2) {
        top: 102px;
        left: 630px;
        width: 618px;
      }

      &:nth-child(3) {
        top: 432px;
        left: 630px;
        width: 354px;
      }

      &:nth-child(4) {
        top: 462px;
        left: 1020px;
        width: 477px;
      }
    }

    @include media-query($medium-down) {
      &:nth-child(2), &:nth-child(4) { display: none; }
    }
  }
}

.PageExplosion {
  &-line { display: none; }

  @include media-query($medium-up) {
    &-bullet-points {
      position: relative;
      display: block;
      padding: 0;
      margin: 0;
      list-style: none;
      z-index: 1000;

      li {
        position: absolute;
        width: 240px;
      }
    }

    .PageExplosion-line {
      display: block;
      border-left: 1px dashed $color-white;
      margin-top: $gutter-space / 2;
      opacity: .45;
    }
  }

  @include media-query($large-down) {
    .PageExplosion-bullet-points li {
      width: 180px;
    }
  }

  @include media-query($medium-down) {
    .PageExplosion-bullet-points li {
      width: auto;
    }
  }
}

.PageHeader {
  z-index: 0;
  
  &-image,
  &-video {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -100;

    @include media-query($small-down) {
      display: none;
    }
  }

  &-image-mobile {
     position: relative;
     margin-bottom: -30%;
     width: 100%;

     @include media-query($small-up) {
       display: none;
     }
  }

  &-video video { width: 100%; }
}

.PageLightUI {
  &-background {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 300px;
    width: 100vw;
    background-color: $color-grey-background;
    z-index: -100;
  }
}

.PageLoadingIndicator {
  height: 100vh;
  width: 100vw;
}

.PageSlider {
  &-arrow {
    position: absolute;
    top: 50%;
    height: 60px;
    width: 60px;
    @include border-radius(100%);
    cursor: pointer;
    @include transition(all .25s ease-in-out);
    z-index: 1000;

    &.next {
      right: 60px;
      transform: translateY(-50%);
    }

    &.prev {
      left: 60px;
      transform: rotate(180deg) translateY(50%);
    }

    @include media-query($medium-down) {
      top: auto;
      bottom: -60px;
      height: 42px;
      width: 42px;

      &.next {
        right: $gutter-space / 2;
        transform: translateY(0);
      }

      &.prev {
        left: $gutter-space / 2;
        transform: rotate(180deg) translateY(0);
      }
    }
  }

  &-slider {
    position: relative;

    .slick-dots {      
      @include media-query($medium-up) {
        visibility: hidden;
      }
    }
  }

  &.text {
    max-width: 900px;
    min-height: 90px;
    margin: $gutter-space * 2 auto 0;
    text-align: center;

    @include media-query($medium-down) {
      margin: $gutter-space * 4 auto 0;
    }
  }

  &.mobile-title {
    @include media-query($medium-up) {
      display: none;
    }
  }

  &-nav {
    max-width: 900px;
    margin: $gutter-space * 2 auto 0;

    @include media-query($medium-down) {
      display: none;
    }

    ul {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin: 0;
      list-style: none;

      @include media-query($small-down) { padding: 6px 0; }

      li {
        padding-right: $gutter-space;
        text-transform: uppercase;
        white-space: nowrap;
        opacity: .35;
        @include transition(opacity .25s ease-in-out);

        @include media-query($small-down) {
          display: inline-block;
        }

        &:last-child {
          padding: 0;

          @include media-query($small-down) {
            padding-right: $gutter-space / 2;
          }
        }

        &:hover,
        &.active {
          opacity: 1;
          @include transition(opacity .25s ease-in-out);
        }

        &:hover { cursor: pointer; }
        &.active { cursor: default; }

        &.active:after {
          position: relative;
          top: 1px;
          left: 0;
          display: block;
          height: 1px;
          width: 100%;
          content: "";

          @include media-query($small-down) {
            top: 6px;
          }
        }
      }
    }
  }

  &-slide {
    img {
      top: 50%;
      width: 100%;
      margin: 0 auto;
    }
  }
}

.PageTeaser {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 54px 84px;
  margin-bottom: 16px;
  overflow: hidden;
  
  @include media-query($medium-down) {
    padding: 30px;
  }
  
  p { margin-bottom: 48px; }
  
  &.dark {
    background-color: $color-black-background;
    color: $color-white;
  }
  
  &.white {
    background: $color-white;
  }
}

.Modal {
  &-button-close {
    position: fixed;
    top: $gutter-space;
    right: $gutter-space;

    svg {
      fill: #fff;
      cursor: pointer;
    }
  }

  &-content {
    width: 100%;
    max-width: $large-viewport;
    padding: 0;
    background: transparent;
    box-shadow: none;
  }

  &-overlay {
    background-color: rgba(1, 1, 17, .8);
    z-index: 1000000;
  }

  &-video-container {
    position: relative;
    display: block;
    height: 0;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 56.25%;
    overflow: hidden;

    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
