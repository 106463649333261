$xxlarge-viewport: 1900px;
$xlarge-viewport: 1440px; /* 1440+ */
$large-viewport: 1200px;  /* 1200 - 1440 */
$medium-viewport: 992px;  /* 992 - 1200 */
$small-viewport: 768px;   /* 768 - 992 */

$grid-small: $small-viewport;
$grid-medium: $medium-viewport;
$grid-large: $large-viewport;
$grid-xlarge: $xlarge-viewport;
$grid-widescreen: $xxlarge-viewport;

$small: "small";
$small-down: "small-down";
$small-up: "small-up";
$medium: "medium";
$medium-down: "medium-down";
$medium-up: "medium-up";
$large: "large";
$large-down: "large-down";
$large-up: "large-up";
$xlarge: "xlarge";
$xlarge-down: "xlarge-down";
$xlarge-up: "xlarge-up";
$widescreen: "widescreen";

$grid-breakpoints: (
  $small "(min-width: #{$grid-small}) and (max-width: #{$grid-medium - 1})",
  $small-down "(max-width: #{$grid-small - 1})",
  $small-up "(min-width: #{$grid-small})",
  $medium "(min-width: #{$grid-medium}) and (max-width: #{$grid-large - 1})",
  $medium-down "(max-width: #{$grid-medium - 1})",
  $medium-up "(min-width: #{$grid-medium})",
  $large "(min-width: #{$grid-large}) and (max-width: #{$grid-xlarge - 1})",
  $large-down "(max-width: #{$grid-large - 1})",
  $large-up "(min-width: #{$grid-large})",
  $xlarge "(min-width: #{$grid-xlarge}) and (max-width: #{$grid-widescreen - 1})",
  $xlarge-down "(max-width: #{$grid-xlarge - 1})",
  $xlarge-up "(min-width: #{$grid-xlarge})",
  $widescreen "(min-width: #{$grid-widescreen})"
);

@mixin media-query($media-query) {
  $breakpoint-found: false;
  @each $breakpoint in $grid-breakpoints {
    $name: nth($breakpoint, 1);
    $declaration: nth($breakpoint, 2);
    @if $media-query == $name and $declaration {
      $breakpoint-found: true;
      @media only screen and #{$declaration} {
        @content;
      }
    }
  }
  @if $breakpoint-found == false {
    @warn "Breakpoint "#{$media-query}" does not exist";
  }
}

$color-black-background: #010111;
$color-black: #333;
$color-blue: #05164D;
$color-bluebreeze: #000036;
$color-green: #407E62;
$color-grey-background: #F0F0F0;
$color-grey-dark: #666;
$color-grey-medium: #878787;
$color-grey-light: #C5C5C5;
$color-grey-light2: #E1E1E1;
$color-red: #CC241C;
$color-white: #FFF;
$color-yellow: #FFAD00;

$gutter-space: 24px;

$padding-xxl: 240px;
$padding-xl: 180px;
$padding-lg: 120px;
$padding-md: 90px;
$padding-sm: 60px;
$padding-xs: 30px;
$padding-xxs: 18px;

.padtop-xxl { padding-top: $padding-xxl; }
.padtop-xl { padding-top: $padding-xl; }
.padtop-lg { padding-top: $padding-lg; }
.padtop-md { padding-top: $padding-md; }
.padtop-sm { padding-top: $padding-sm; }
.padtop-xs { padding-top: $padding-xs; }
.padtop-xxs { padding-top: $padding-xxs; }

.padbot-xxl { padding-bottom: $padding-xxl; }
.padbot-xl { padding-bottom: $padding-xl; }
.padbot-lg { padding-bottom: $padding-lg; }
.padbot-md { padding-bottom: $padding-md; }
.padbot-sm { padding-bottom: $padding-sm; }
.padbot-xs { padding-bottom: $padding-xs; }
.padbot-xxs { padding-bottom: $padding-xxs; }

.padtop-xxl,
.padtop-xl,
.padtop-lg,
.padtop-md {
  @include media-query($small-down) {
    padding-top: $padding-sm;
  }
}

.padtop-sm,
.padtop-xs {
  @include media-query($small-down) {
    padding-top: $padding-xs;
  }
}

.padbot-xxl,
.padbot-xl,
.padbot-lg,
.padbot-md {
  @include media-query($small-down) {
    padding-bottom: $padding-sm;
  }
}

.padbot-sm,
.padbot-xs {
  @include media-query($small-down) {
    padding-bottom: $padding-xs;
  }
  
}

.padtop {
  @include media-query($medium-down) {
    &-none-mobile { padding-top: 0; }
    &-sm-mobile { padding-top: $gutter-space / 2; }
    &-md-mobile { padding-top: $gutter-space; }
    &-lg-mobile { padding-top: $gutter-space * 2; }
    &-xl-mobile { padding-top: $gutter-space * 3; }
  }
}

.padbot {
  @include media-query($medium-down) {
    &-none-mobile { padding-bottom: 0; }
    &-sm-mobile { padding-bottom: $gutter-space / 2; }
    &-md-mobile { padding-bottom: $gutter-space; }
    &-lg-mobile { padding-bottom: $gutter-space * 2; }
    &-xl-mobile { padding-bottom: $gutter-space * 3; }
  }
}


@mixin animation($name, $timing, $fill) {
  -webkit-animation-name: $name;
  -webkit-animation-timing-function: $timing;
  -webkit-animation-fill-mode: $fill;
     -moz-animation-name: $name;
     -moz-animation-timing-function: $timing;
     -moz-animation-fill-mode: $fill;
       -o-animation-name: $name;
       -o-animation-timing-function: $timing;
       -o-animation-fill-mode: $fill;
          animation-name: $name;
          animation-timing-function: $timing;
          animation-fill-mode: $fill;
}

@mixin border-radius($args...) {
  -webkit-border-radius: $args;
     -moz-border-radius: $args;
       -o-border-radius: $args;
	        border-radius: $args;
}

@mixin hover-fix {
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1.0, 1.0);
  transform: translateZ(0);
}

@mixin transition($args...) {
  -webkit-transition: $args;
     -moz-transition: $args;
       -o-transition: $args;
          transition: $args;
}

@mixin transform($args...) {
  -webkit-transform: $args;
     -moz-transform: $args;
       -o-transform: $args;
          transform: $args;
}
