$slide-distance: 66px;

.fade-in { @include animation(fade-in, ease-in-out, both ); }

@-webkit-keyframes fade-in {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fade-in {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}


.fade-in-up { @include animation(fade-in-up, cubic-bezier(.645, .045, .355, 1), both); }

@include media-query($small-up) {
  @-webkit-keyframes fade-in-up {
    0% {
      @include transform(translateY($slide-distance));
      opacity: 0;
    }
    100% {
      @include transform(translateY(0));
      opacity: 1;
    }
  }

  @keyframes fade-in-up {
    0% {
      @include transform(translateY($slide-distance));
      opacity: 0;
    }
    100% {
      @include transform(translateY(0));
      opacity: 1;
    }
  }
}

@include media-query($small-down) {
  @-webkit-keyframes fade-in-up {
    0% {
      @include transform(translateY($slide-distance / 2));
      opacity: 0;
    }
    100% {
      @include transform(translateY(0));
      opacity: 1;
    }
  }

  @keyframes fade-in-up {
    0% {
      @include transform(translateY($slide-distance / 2));
      opacity: 0;
    }
    100% {
      @include transform(translateY(0));
      opacity: 1;
    }
  }
}


.fade-scale-rotate { @include animation(fade-scale-rotate, cubic-bezier(.215, .610, .355, 1), both); }

@-webkit-keyframes fade-scale-rotate {
  0% {
    @include transform(rotate(-2deg) scale(1.05));
    opacity: .36;
  }
  100% {
    @include transform(rotate(0deg) scale(1));
    opacity: 1;
  }
}

@keyframes fade-scale-rotate {
  0% {
    @include transform(rotate(-2deg) scale(1.05));
    opacity: .36;
  }
  100% {
    @include transform(rotate(0deg) scale(1));
    opacity: 1;
  }
}


.pulse {
  @include animation(pulse, cubic-bezier(.215, .610, .355, 1), both);
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
}

@-webkit-keyframes pulse {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes pulse {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}


.slide-up { @include animation(slide-up, cubic-bezier(.645, .045, .355, 1), both); }

@include media-query($small-up) {
  @-webkit-keyframes slide-up {
    0% { @include transform(translateY($slide-distance)); }
    100% { @include transform(translateY(0)); }
  }

  @keyframes slide-up {
    0% { @include transform(translateY($slide-distance)); }
    100% { @include transform(translateY(0)); }
  }
}

@include media-query($small-down) {
  @-webkit-keyframes slide-up {
    0% { @include transform(translateY($slide-distance / 2)); }
    100% { @include transform(translateY(0)); }
  }

  @keyframes slide-up {
    0% { @include transform(translateY($slide-distance / 2)); }
    100% { @include transform(translateY(0)); }
  }
}



.fade-enter {
  opacity: 0;

  &.fade-enter-active {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    @include transition(opacity 1s cubic-bezier(.645, .045, .355, 1) .5s);
  }

  &-done {
    position: relative;
    opacity: 1;
  }
}

.fade-exit {
  opacity: 1;

  &.fade-exit-active {
    opacity: 0;
    @include transition(opacity .5s cubic-bezier(.645, .045, .355, 1));
  }
}
